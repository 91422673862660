
import Categories from "./Categories";

const Home = () => {
  return (
    <div className="flex flex-col  min-w-[374px] max-w-[1500px] maxi:m-auto">
      <Categories />
    </div>
  );
};

export default Home;
